@import '../../../../styles/customMediaQueries.css';

.root {
    height: var(--topbarHeight);
    background-color: var(--colorAttention);
    box-shadow: var(--boxShadowLight);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media (--viewportLarge) {
        height: var(--topbarHeightDesktop);
    }
}

.meetingButton {
    padding: 0 12px;
    min-height: 34px;
    font-size: 14px;
    width: fit-content;
}
@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accountLinksWrapper {
  margin-bottom: 36px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);

  display: flex;
  gap: 24px;
}

.avatar {
  /* Layout */
  flex-shrink: 0;
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 0;
  margin-bottom: 0;
  margin-top: 0px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);
  text-align: left;

  /* Position component */
  width: initial;
  margin: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 48px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 32px;
}

.profileContainer {
  margin: var(--TopbarMobileMenu_topMargin) 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.profileActions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.topbarLink {
  flex-shrink: 0;
  transition: var(--transitionStyleButton);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 16px;

  &:focus {
    color: var(--colorBlack);
    text-decoration: none;
  }
}

.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  text-decoration: inherit;
  position: relative;
}

.switchLinkLabel {
  composes: topbarLinkLabel;
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  border-radius: 8px;
  padding: 6px 10px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.highlight {
  color: var(--colorFail);

  &:hover {
    color: var(--colorFailDark);
  }
}

.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);
  color: var(--colorFail);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 75%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}